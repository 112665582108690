import {
    CATEGORY_FILTER_OVERLAY_ID,
    KEY_PRICE
} from 'SourceComponent/CategoryFilterOverlay/CategoryFilterOverlay.config';

export {
    CATEGORY_FILTER_OVERLAY_ID,
    KEY_PRICE
};

export const DEFAULT_FILTERS = [
    {
        attribute_code: 'country',
        attribute_label: 'Herkunft',
        attribute_position: 0,
        attribute_type: 'select',
        has_swatch: false,
        is_boolean: false
    },
    {
        attribute_code: 'brand',
        attribute_label: 'Marke',
        attribute_position: 0,
        attribute_type: 'select',
        has_swatch: false,
        is_boolean: false
    },
    {
        attribute_code: 'subbrand',
        attribute_label: 'Untermarke',
        attribute_position: 0,
        attribute_type: 'select',
        has_swatch: false,
        is_boolean: false
    },
    {
        attribute_code: 'format',
        attribute_label: 'Format',
        attribute_position: 0,
        attribute_type: 'select',
        has_swatch: false,
        is_boolean: false
    }
];

export const GROUP_FILTER = {
    attribute_code: 'list',
    attribute_label: 'Product Group', // wont be shown
    attribute_position: 1,
    attribute_type: 'select',
    attribute_options: {
        455: {
            count: 0,
            label: 'Limited Edition',
            value_string: '455'
        },
        975: {
            count: 0,
            label: 'Box-pressed',
            value_string: '975'
        },
        2021: {
            count: 0,
            label: 'Tubo',
            value_string: '2021'
        },
        2022: {
            count: 0,
            label: 'Shaggy Foot',
            value_string: '2022'
        }
    },
    attribute_values: ['455', '975', '2021', '2022'],
    has_swatch: false,
    is_boolean: false
};

/** @namespace Scandipwa/Component/CategoryFilterOverlay/Config/ringGaugeToDiameter */
// eslint-disable-next-line no-magic-numbers
export const ringGaugeToDiameter = (ringGauge) => (ringGauge / 64) * 25.4;

export const SLIDER_FILTERS = [
    {
        code: 'length_mm',
        label: __('Length'),
        min: 0,
        max: 250,
        // eslint-disable-next-line no-magic-numbers
        formatLabelValue: (min, max) => `${ (min / 10).toFixed(1) }-${ (max / 10).toFixed(1) } cm`
    },
    {
        code: 'ring_gauge',
        label: __('Ringgauge'),
        min: 0,
        max: 100,
        // eslint-disable-next-line max-len
        formatLabelValue: (min, max) => `${ min }-${ max }\xA0\xA0\xA0Ø ${ ringGaugeToDiameter(min).toFixed(1) }-${ ringGaugeToDiameter(max).toFixed(1) } mm`
    },
    {
        code: 'price',
        label: __('Price per piece'),
        min: 0,
        max: 300,
        formatLabelValue: (min, max) => `${ min }-${ max } CHF`
    },
    {
        code: 'flavor_strength',
        label: __('Strength'),
        min: 0,
        max: 100,
        // eslint-disable-next-line max-len
        tooltip: __('The intensity of the fullness of flavors perceived by the tongue and palate plus the palette of smells perceived by the nose. The more filled in smoke symbols, the more powerful.'),
        formatLabelValue: (min, max) => `${ min }-${ max }`
    },
    {
        code: 'aroma',
        label: __('Aroma'),
        min: 0,
        max: 100,
        // eslint-disable-next-line max-len
        tooltip: __('The combination of the fullness of flavors perceived by the tongue and palate plus the palette of smells perceived by the nose. The more smoke symbols filled in, the more aromatic.'),
        formatLabelValue: (min, max) => `${ min }-${ max }`
    }
];

export const SLIDER_FILTERS_CODES = [
    'length_mm',
    'ring_gauge',
    'flavor_strength',
    'aroma',
    'price'
];
